
import {reactive, toRefs} from 'vue';

// 定义一个接口，约束favorites的类型
interface Img {
  img: string;
  id: number;
  // id: string; 如果定义是String类型但是数据是number类型的就会提示出错
}

export default {
  name: '',
  props: {
    data: {
      type: Object as () => Img,
      //   type: Object,
    },
    width: {
      type: Number,
      default: 300, //图片宽度,默认300px
    },
    height: {
      type: Number,
      default: 200, //图片高度,默认200px
    },
    showClose: {
      type: Boolean,
      default: false, //图片右上角的关闭按钮,默认不显示
    },
    showTitle: {
      type: Boolean,
      default: false, //图片右上角的关闭按钮,默认不显示
    },
    showName: {
      type: Boolean,
      default: false, //图片中间的文字,默认不显示
    },
    showBottomText: {
      type: Boolean,
      default: true, //图片下方的文字,默认显示
    },
    showBottomTitle: {
      type: Boolean,
      default: false, //图片下方的名字,默认不显示
    },
    flexable: {
      //为true时,图片自适应父级元素宽度,否则是默认的宽度或则设置的宽度
      type: Boolean,
      default: false,
    },
    titleOnTop: {
      //为true时,图片title默认在上
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const methods = {
      deleteItem(id) {
        context.emit('deleteItem', id);
      },
      clickImg(id){
        context.emit('clickImg', id);
      }
    };
    return {
      ...methods,
    };
  },
};
