import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d95cb78"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title textColor"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "favorite",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickImg($props.data.id)))
  }, [
    ($props.showTitle && $props.titleOnTop)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($props.data.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      class: "displayImg",
      style: _normalizeStyle({
      width: $props.flexable == true ? '100%' : $props.width + 'px',
      height: $props.flexable == true ? _ctx.auto : $props.height + 'px',
    }),
      src: $props.data.img||$props.data.image,
      alt: ""
    }, null, 12, _hoisted_2),
    _withDirectives(_createElementVNode("i", {
      class: "mainBackground closeIcon iconfont icon-guanbi",
      style: {"font-size":"20px","color":"#fff"},
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.deleteItem($props.data.id)), ["stop"]))
    }, null, 512), [
      [_vShow, $props.showClose]
    ]),
    _withDirectives(_createElementVNode("p", {
      class: "textColor bottomName",
      style: _normalizeStyle({
      width: $props.flexable == true ? '100%' : $props.width + 'px',
    })
    }, _toDisplayString($props.data.title), 5), [
      [_vShow, $props.showName]
    ]),
    ($props.showTitle && !$props.titleOnTop)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "title textColor",
          style: _normalizeStyle({
      width: $props.flexable == true ? '100%' : $props.width + 'px',
    })
        }, _toDisplayString($props.data.title), 5))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("p", {
      class: "bottomText textColor",
      style: _normalizeStyle({
      width: $props.flexable == true ? '100%' : $props.width + 'px',
    })
    }, _toDisplayString($props.data.description), 5), [
      [_vShow, $props.showBottomText]
    ]),
    _withDirectives(_createElementVNode("p", {
      class: "bottomText textColor",
      style: _normalizeStyle({
      width: $props.flexable == true ? '100%' : $props.width + 'px',
    })
    }, _toDisplayString($props.data.name), 5), [
      [_vShow, $props.showBottomTitle]
    ])
  ]))
}