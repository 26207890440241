
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
} from 'vue';
import imgBox from '@/components/page/imgBox.vue';
import {ElMessageBox} from 'element-plus';
import {ElMessage} from 'element-plus';
import axios from '@/api/http.js';
import {useRouter} from 'vue-router'

// 定义一个接口，约束imgBox的类型
interface Img {
  img: string;
  id: number;
  description?: string,
  // id: string; 如果定义是String类型但是数据是number类型的就会提示出错
}

export default defineComponent({
  name: 'Favorites',
  components: {
    imgBox,
  },

  setup() {
    // 无限滚动（只能垂直的，现在还不知道怎么适应flex布局）
    const client = localStorage.getItem("eventClient");
    const count = ref(0);
    const router = useRouter();
    const load = () => {
      console.log('dataLength:', dataLength);
      // let newData = [
      //     {
      //     img: require("../../assets/imgs/favorite.jpg"),
      //     id: 1,
      //     description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
      //   },   {
      //     img: require("../../assets/imgs/favorite.jpg"),
      //     id: 1,
      //     description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
      //   },   {
      //     img: require("../../assets/imgs/favorite.jpg"),
      //     id: 1,
      //     description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
      //   },   {
      //     img: require("../../assets/imgs/favorite.jpg"),
      //     id: 1,
      //     description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
      //   },   {
      //     img: require("../../assets/imgs/favorite.jpg"),
      //     id: 1,
      //     description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
      //   },
      // ];
      // data.favorites = data.favorites.concat(newData);
    };

    const data = reactive<{ favorites: Img[] }>({
      favorites: [
        // {
        //   img: require("../../assets/imgs/favorite.jpg"),
        //   id: 1,
        //   description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
        // },
        //  {
        //   img: require("../../assets/imgs/favorite.jpg"),
        //   id: 1,
        //   description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
        // },   {
        //   img: require("../../assets/imgs/favorite.jpg"),
        //   id: 1,
        //   description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
        // },   {
        //   img: require("../../assets/imgs/favorite.jpg"),
        //   id: 1,
        //   description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
        // },   {
        //   img: require("../../assets/imgs/favorite.jpg"),
        //   id: 1,
        //   description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
        // },   {
        //   img: require("../../assets/imgs/favorite.jpg"),
        //   id: 1,
        //   description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect",
        // }

      ],
    });
    const dataLength = data.favorites.length;
    const deleteItem = (id) => {
      // console.log("id:", id);
      ElMessageBox.confirm(
          'Are you sure you want to delete?  This can not be undone.',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      )
          .then(() => {
            axios.request({
              method: 'POST',
              url: '/m/updateMyAgenda/',
              data: {
                agendaId: id,
                meetingId: 'a7yyxczuty0qpjo2393gmyad',
                // meetingId: localStorage.eventsMeetingId,
                isAdd: 0
              }
            }).then(res => {
              let length = data.favorites.length;
              for (let i = 0; i < length; i++) {
                let item = data.favorites[i];
                if (id == item.id) {
                  data.favorites.splice(i, 1);
                  break
                }
              }

              // ElMessage({
              //   type: 'success',
              //   message: 'successfully deleted',
              // });
            }).catch(err => {
              console.log(err);
            });
          })
          .catch(() => {
            // ElMessage({
            //   type: 'info',
            //   message: 'Undeleted',
            // });
          });
    };
    const clickImg = (id) => {
      if(client=='inpixon'){
        router.push({
          name: 'KeynoteDetail',
          query: {id: id},
        });
      }else{
        router.push({
          name: 'Content',
          query: {id: id},
        });
      }
    };
    const getData = () => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/favorites',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        console.log(res.data.data);
        data.favorites = data.favorites.concat(res.data.data);
      });
    };
    // getData();
    onBeforeMount(() => {
    });
    onMounted(() => {
      getData();
    });
    return {
      ...toRefs(data),
      router,
      deleteItem,
      clickImg,
      count,
      load,
      getData,
      client
    };
  },
});
