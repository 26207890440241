import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-481d6aaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "favorites pagePadding" }
const _hoisted_2 = {
  key: 0,
  class: "pageTitle textColor"
}
const _hoisted_3 = {
  key: 1,
  class: "pageTitle textColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_imgBox = _resolveComponent("imgBox")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.client=='inpixon')
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "My Agenda"))
      : (_openBlock(), _createElementBlock("h1", _hoisted_3, "My Favorites")),
    _withDirectives((_openBlock(), _createBlock(_component_el_row, {
      class: "infinite-list favoriteUl",
      gutter: "30"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.favorites, (item) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            xs: 24,
            sm: 8,
            lg: 6,
            class: "infinite-list-item favoriteLi",
            key: item,
            style: {"margin-bottom":"30px","cursor":"pointer"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_imgBox, {
                data: item,
                onDeleteItem: _ctx.deleteItem,
                onClickImg: _ctx.clickImg,
                showClose: true,
                flexable: true
              }, null, 8, ["data", "onDeleteItem", "onClickImg"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })), [
      [_directive_infinite_scroll, _ctx.load]
    ])
  ]))
}